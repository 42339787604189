export enum MetadataGetters {
    CurrentAudio = 'metadata/currentAudio',
    QueueLength = 'metadata/queueLength',
    CurrentQueuePosition = 'metadata/currentQueuePosition',
    IsListenLaterList = 'metadata/isListenLaterList',
    PlaylistTitle = 'metadata/playlistTitle',
    Queue = 'metadata/queue',
    QueueIds = 'metadata/queueIds',
    GetAudioByQueuePosition = 'metadata/getAudioByQueuePosition',
    CurrentAudioKey = 'metadata/currentAudioKey',
    PlaylistId = 'metadata/playlistId'
}
