import storage from '@/common/storage';
import { AudioMetadata } from '@/components/models/';
import { AudioMutations } from '@/store/audio/AudioMutations';
import { AudioState } from '@/store/audio/AudioState';
import { AudioStateEventListener } from '@/store/audio/AudioStateEventListener';
import { PlaybackEventListener } from '@/store/audio/playbackEventListener';
import { TrackingState } from '@/store/audio/TrackingState';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { RootGetters } from '@/store/RootGetters';
import { PlayerTrackEventListener } from '@/tracking/PlayerTrackEventListener';
import { PlayerErrorEventListener } from '@/tracking/PlayerErrorEventListener';
import { ListenDurationTrackerEventListener } from '@/tracking/ListenDurationTrackerEventListener';
import { StoreOptions } from 'vuex';

export default {
    namespaced: true,
    state: {
        position: 0,
        duration: 0,
        buffered: 0,
        error: null,
        isPlaying: false,
        volume: 1,
        isStalled: false,
        audioElement: new Audio(),
        trackingState: {},
        disposableEventListeners: [],
        persistentEventListeners: []
    },
    getters: {
        position: state => state.position,
        duration: state => state.duration,
        isPlaying: state => state.isPlaying,
        isStalled: state => state.isStalled,
        buffered: state => state.buffered,
        volume: state => state.volume * 100,
        error: state => state.error
    },
    actions: {
        pause: async ({ state }) => state.audioElement.pause(),
        seek: async ({ state }, position: number) => (state.audioElement.currentTime = position),
        async play({ state }) {
            if (!state.audioElement.src) {
                return;
            }

            if (state.error) {
                state.audioElement.load();
            }

            await state.audioElement.play();
        },
        async setVolume({ state, commit }, volume: number) {
            state.audioElement.volume = volume / 100;
            commit(AudioMutations.SetVolume);
        },
        async set(
            { dispatch, state, commit },
            { audio, startTime, trackingState = {} }: { audio: AudioMetadata; startTime?: number; trackingState: TrackingState }
        ) {
            if (!audio?.src) {
                return;
            }

            if (!state.persistentEventListeners.length) {
                await dispatch(InternalAction.AddPersistentEventListeners);
            }

            if (state.disposableEventListeners.length) {
                await dispatch(InternalAction.DisposeDisposableEventListeners);
            }

            commit(AudioMutations.SetTrackingState, trackingState);

            state.audioElement.src = audio.src;
            state.audioElement.title = audio.episodeTitle;

            await dispatch(InternalAction.AddDisposableEventListeners, { audio, startTime });
        },
        async touchForLegacySafari({ state }) {
            if (!state.audioElement.src) {
                state.audioElement.load();
            }
        },
        async unload({ dispatch }) {
            await dispatch(InternalAction.DisposeDisposableEventListeners);
        },
        async resetPosition({ commit, state }) {
            commit(AudioMutations.SetPosition);
        },
        async _addPersistentEventListeners({ commit, state }) {
            commit(AudioMutations.RegisterPersistentEventListener, new AudioStateEventListener(commit, state.audioElement));
        },
        async _addDisposableEventListeners(
            { commit, dispatch, state, rootGetters },
            { audio, startTime }: { audio: AudioMetadata; startTime?: number }
        ) {
            const isEmbed = rootGetters[RootGetters.IsEmbed];

            commit(
                AudioMutations.RegisterDisposableEventListener,
                new PlaybackEventListener(dispatch, rootGetters, state.audioElement, startTime)
            );
            commit(AudioMutations.RegisterDisposableEventListener, new PlayerErrorEventListener(state.audioElement, isEmbed));
            commit(
                AudioMutations.RegisterDisposableEventListener,
                new PlayerTrackEventListener(state.audioElement, audio, state.trackingState)
            );
            commit(
                AudioMutations.RegisterDisposableEventListener,
                new ListenDurationTrackerEventListener(state.audioElement, audio, isEmbed)
            );
        },
        _disposeDisposableEventListeners: async ({ commit }) => commit(AudioMutations.Dispose)
    },
    mutations: {
        setPosition(state): void {
            state.position = state.audioElement.currentTime;
            storage.audioPosition = state.audioElement.currentTime;
        },
        resetPosition(state): void {
            state.position = 0;
            storage.audioPosition = 0;
        },
        setVolume(state): void {
            state.volume = state.audioElement.volume;
            storage.volume = state.audioElement.volume * 100;
        },
        setTrackingState: (state, trackingState: TrackingState) => (state.trackingState = trackingState),
        setIsPlaying: (state, isPlaying: boolean) => (state.isPlaying = isPlaying),
        setDuration: state => (state.duration = state.audioElement.duration),
        setError: state => (state.error = state.audioElement.error),
        setBuffered: state =>
            (state.buffered = state.audioElement.buffered?.length
                ? state.audioElement.buffered.end(state.audioElement.buffered.length - 1)
                : 0),
        setIsStalled: (state, isStalled: boolean) => (state.isStalled = isStalled),
        registerDisposableEventListener: (state, disposable: DisposableEventListener) =>
            state.disposableEventListeners.push(disposable),
        registerPersistentEventListener: (state, eventListenerObject: EventListenerObject) =>
            state.persistentEventListeners.push(eventListenerObject),
        dispose(state) {
            state.disposableEventListeners.map(o => o.dispose());
            state.disposableEventListeners = [];
        }
    }
} as StoreOptions<AudioState>;

enum InternalAction {
    DisposeDisposableEventListeners = '_disposeDisposableEventListeners',
    AddDisposableEventListeners = '_addDisposableEventListeners',
    AddPersistentEventListeners = '_addPersistentEventListeners',
    Load = 'load',
    Play = 'play'
}
