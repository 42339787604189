import userState from '@/common/user-state.js';
import { StoreOptions } from 'vuex';
import api, { ListenLaterResponse } from '@/api/listen-later-api';

export default {
    namespaced: true,
    state: {
        _items: [],
        _numberOfWaitingItems: -1
    },
    getters: {
        items: (state): ListenLaterAudioItem[] => state._items,
        itemsWithAudio: (state, getters): ListenLaterAudioItem[] =>
            getters.items.filter((item: ListenLaterAudioItem) => item && item.audio.hasAudio),
        ready: (state): boolean => state._numberOfWaitingItems === 0
    },
    actions: {
        load(context) {
            context.commit('clearItems');
            userState.listenLater.get((audiosInStore: UserStateAudioItem[]) => {
                if (!audiosInStore) {
                    context.commit('setNumberOfWaitingItems', 0);
                    return;
                }

                context.commit('setNumberOfWaitingItems', audiosInStore.length);
                for (let i = 0; i < audiosInStore.length; i++) {
                    loadAudioAsync(audiosInStore[i], i, context);
                }
            });
        },
        updateList(context, list: ListenLaterAudioItem[]) {
            context.commit('setItems', list);
            const userStateItems = list.map((item: ListenLaterAudioItem) => {
                return {
                    id: item.audio.audioId,
                    type: item.audio.audioType
                };
            });
            userState.listenLater.setList(userStateItems);
        },
        removeItem(context, item: ListenLaterResponse) {
            if (!item) {
                return;
            }
            for (let i = 0; i < context.state._items.length; i++) {
                const audio = context.state._items[i].audio;
                if (audio && audio.audioId === item.audioId && audio.audioType === item.audioType) {
                    context.commit('removeItemAt', i);
                    userState.listenLater.remove(audio.audioId, audio.audioType);
                }
            }
        }
    },
    mutations: {
        addIndexedItem(state, item: ListenLaterAudioItem) {
            state._items.push(item);
            state._items.sort((x, y) => x.index - y.index);
        },
        removeItemAt(state, index: number) {
            state._items.splice(index, 1);
        },
        setNumberOfWaitingItems(state, count: number) {
            state._numberOfWaitingItems = count;
        },
        clearItems(state) {
            state._items = [];
        },
        setItems(state, items: ListenLaterAudioItem[]) {
            state._items = items;
        }
    }
} as StoreOptions<State>;

function loadAudioAsync(item: UserStateAudioItem, index: number, context: any): void {
    if (!item) {
        return;
    }

    api.getAudio({
        id: item.id,
        type: item.type
    })
        .then(response => {
            const listenLaterItem = {
                audio: response.data,
                index: index
            };
            context.commit('addIndexedItem', listenLaterItem);
            context.commit('setNumberOfWaitingItems', context.state._numberOfWaitingItems - 1);
        })
        .catch(() => {
            context.commit('setNumberOfWaitingItems', context.state._numberOfWaitingItems - 1);
        });
}

type State = {
    _items: ListenLaterAudioItem[];
    _numberOfWaitingItems: number;
};

type UserStateAudioItem = {
    id: number;
    type: string;
};

export type ListenLaterAudioItem = {
    audio: ListenLaterResponse;
    index: number;
};
