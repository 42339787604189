import httpClient from '@/common/http-client';
import { Branding } from '@/components/models/Branding';

export default {
    getAudio(params: ListenLaterRequest) {
        const url = '/minlista/getlistenlater';
        return httpClient.get<ListenLaterResponse>(url, params);
    }
};

type ListenLaterRequest = {
    id: number;
    type: string;
};

export interface ListenLaterResponse {
    audioId: number;
    audioType: string;
    duration: string;
    durationAbbr: string;
    hasAudio: boolean;
    imageUrl: string;
    programName: string;
    programTheme: Branding;
    firstListenableDate: string;
    title: string;
    url: string;
}
