import { AllChannelsResponse, Channel } from '@/api/channels-api';
import { LegacyLivePlayerGetter } from '@/enums/LegacyLivePlayerGetter';
import { PlayerMode } from '@/enums/PlayerMode';
import audioStore from '@/store/audio/AudioStore';
import channelsStore from '@/store/channels-store';
import CommonStore from '@/store/common/CommonStore';
import { CommonStoreMutation } from '@/store/common/CommonStoreMutations';
import listenLaterStore from '@/store/listen-later-store';
import localNewsStore from '@/store/local-news-store';
import metadataStore from '@/store/metadata/MetadataStore';
import playerStore from '@/store/player-store';
import { RootState } from '@/store/RootState';
import searchStore from '@/store/search-store';
import slideshowStore from '@/store/slideshow-store.js';
import trafficStore from '@/store/traffic-store';
import { StoreOptions } from 'vuex';
import { MetadataGetters } from '@/store/metadata/MetadataGetters';
import { LegacyPlayerAction } from '@/enums/LegacyPlayerAction';
import { BackendDataType } from './BackendDataType';

let progressbarInterval: any;
const progressbarIntervalMilliseconds = 1000;

export default {
    getters: {
        timeEpoch: state => state.common._timeEpoch,
        isEmbed: state => state.common._isEmbed,
        isInitialized: state => state.common._isInitialized,
        backendData() {
            return (dataType: BackendDataType): Channel[] | undefined => {
                const json = document.getElementById('backend-data')?.getAttribute('data-json');

                if (!json) {
                    return;
                }

                const backendData: BackendData = JSON.parse(json);

                if (dataType === BackendDataType.Channel) {
                    return backendData.channels?.items;
                }
            };
        },
        playerMode(state, getters): PlayerMode {
            if (getters[MetadataGetters.QueueLength]) {
                return PlayerMode.Ondemand;
            }

            if (getters[LegacyLivePlayerGetter.isHlsEnabled] && getters[LegacyLivePlayerGetter.isActive]) {
                return PlayerMode.Hls;
            }

            if (getters[LegacyLivePlayerGetter.isIcecastActive]) {
                return PlayerMode.Icecast;
            }

            return PlayerMode.Unknown;
        },
        isOndemandPlayerMode: (state, getters): boolean => getters.playerMode === PlayerMode.Ondemand,
        clientWidth: (state): number => state.common._clientWidth
    },
    actions: {
        init({ commit, dispatch, getters }): void {
            if (getters.isInitialized) {
                return;
            }
            dispatch('channels/init', null, { root: true });
            dispatch(LegacyPlayerAction.Init, null, { root: true });
            commit(CommonStoreMutation.UpdateTimeEpoch);
            progressbarInterval = setInterval(() => commit(CommonStoreMutation.UpdateTimeEpoch), progressbarIntervalMilliseconds);
            commit(CommonStoreMutation.SetIsInitialized, true);
            window.addEventListener('resize', () => commit(CommonStoreMutation.UpdateClientWidth));
        },
        destroy(): void {
            clearInterval(progressbarInterval);
        },
        setIsEmbed({ commit }): void {
            commit(CommonStoreMutation.SetIsEmbed);
        }
    },
    modules: {
        player: playerStore,
        traffic: trafficStore,
        search: searchStore,
        localNews: localNewsStore,
        listenLater: listenLaterStore,
        slideshow: slideshowStore,
        channels: channelsStore,
        metadata: metadataStore,
        audio: audioStore,
        common: CommonStore
    }
} as StoreOptions<RootState>;

interface BackendData {
    channels?: AllChannelsResponse;
}
