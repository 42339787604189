export enum metadataMutations {
    clearAll = 'clearAll',
    clearAudios = 'clearAudios',
    SetQueue = 'setQueue',
    AddAudio = 'addAudio',
    SetCurrentIndex = 'setCurrentIndex',
    SetIsListenLaterList = 'setIsListenLaterList',
    SetPlaylistTitle = 'setPlaylistTitle',
    SetPlaylistId = 'setPlaylistId'
}
