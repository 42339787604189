import storage from '@/common/storage';
import { AudioKey } from '@/components/models/AudioKey';
import { AudioQuality } from '@/components/models/AudioQuality';
import { LegacyLivePlayerAction } from '@/enums/LegacyLivePlayerAction';
import { LegacyPlayerAction } from '@/enums/LegacyPlayerAction';
import { PlayerMode } from '@/enums/PlayerMode';
import { AudioActions, AudioGetters, MetadataActions, MetadataGetters } from '@/store';
import { RootGetters } from '@/store/RootGetters';
import { Component, Mixins } from 'vue-property-decorator';
import { LegacyPlayerGetter } from '@/enums/LegacyPlayerGetter';
import { AudioMetadata, AudioType, Branding } from '@/components/models';
import { TrackingState } from '@/store/audio/TrackingState';
import deviceDetection from '@/common/device-detection';
import storyCollectionApi, { StoryModel } from '@/api/story-collection-api';
import dateFormatter from '@/common/date-formatter';
import { PlayerRewindEvent } from '@/common/custom-events/player-interaction/player-rewind-event';
import { PlayerFastforwardEvent } from '@/common/custom-events/player-interaction/player-fastforward-event';
import { PlayerNextAudioEvent } from '@/common/custom-events/player-interaction/player-next-audio-event';
import { PlayerPreviousAudioEvent } from '@/common/custom-events/player-interaction/player-previous-audio-event';
import MetadataMixin from './MetadataMixin';
import { PlayerSeekEvent } from '@/common/custom-events/player-interaction/player-seek-event';
import { PlayerSeekToLiveEvent } from '@/common/custom-events/player-interaction/player-seek-to-live-event';

@Component
export default class PlaybackMixin extends Mixins(MetadataMixin) {
    protected showPlayer = false;

    private get currentQueueIndex(): number {
        return this.$store.getters[MetadataGetters.CurrentQueuePosition];
    }

    protected get quality(): AudioQuality {
        const quality = this.$store.getters[LegacyPlayerGetter.Quality];

        return quality != null ? quality : AudioQuality.Medium;
    }

    protected async setQuality(quality: AudioQuality): Promise<void> {
        await this.$store.dispatch(LegacyPlayerAction.ChangeQuality, quality);

        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            const playlistId = this.$store.getters[MetadataGetters.PlaylistId];
            if (playlistId) {
                await this.handlePlaylistRestore(playlistId);
            } else {
                this.$store.dispatch(MetadataActions.QualityChange);
            }

            const startTime = this.$store.getters[AudioGetters.Position] || 0;
            await this.loadAudioAsync(startTime);
            this.$store.dispatch(AudioActions.Play);
        }
    }

    protected get queueIds(): number[] {
        const queue = this.$store.getters[MetadataGetters.QueueIds];

        return queue ?? [];
    }

    protected resume(): void {
        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            this.$store.dispatch(AudioActions.Play);
        } else {
            this.$store.dispatch(LegacyLivePlayerAction.Resume);
        }
    }

    protected pause(): void {
        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            this.$store.dispatch(AudioActions.Pause);
        } else {
            this.$store.dispatch(LegacyLivePlayerAction.Pause);
        }
    }

    protected play(audio: AudioKey, startTime?: number): void {
        if (audio.type === AudioType.Channel || audio.type === AudioType.Extra || audio.type === AudioType.Music) {
            this.$store.dispatch(LegacyLivePlayerAction.LivePlay, audio);
        } else {
            this.playQueue([audio], 0, '', false, startTime);
        }
    }

    protected rewind(): void {
        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            this.$store.dispatch(AudioActions.Seek, this.$store.getters[AudioGetters.Position] - 15);
        } else if (this.$store.getters[RootGetters.playerMode] === PlayerMode.Hls) {
            this.$store.dispatch(LegacyLivePlayerAction.LiveHlsRewind);
        }

        window.dispatchEvent(new PlayerRewindEvent(this.currentAudio, this.playlistTitle));
    }

    protected fastForward(): void {
        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            this.$store.dispatch(AudioActions.Seek, this.$store.getters[AudioGetters.Position] + 15);
        } else if (this.$store.getters[RootGetters.playerMode] === PlayerMode.Hls) {
            this.$store.dispatch(LegacyLivePlayerAction.LiveHlsFastforward);
        }

        window.dispatchEvent(new PlayerFastforwardEvent(this.currentAudio, this.playlistTitle));
    }

    protected seek(position: number): void {
        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            this.$store.dispatch(AudioActions.Seek, position);
            window.dispatchEvent(new PlayerSeekEvent(this.currentAudio, this.playlistTitle));
        }
    }

    protected async playPrevious(): Promise<void> {
        if (this.isOndemandMode) {
            window.dispatchEvent(new PlayerPreviousAudioEvent(this.currentAudio, this.playlistTitle));
            await this.playPreviousOnDemandAsync();
        } else if (this.isLiveMode) {
            const previousPosition = this.currentQueuePosition - 1;
            const previousStartTime = this.queue[previousPosition]?.startTime;

            if (!previousStartTime) {
                return;
            }

            window.dispatchEvent(new PlayerPreviousAudioEvent(this.currentAudio, this.playlistTitle));
            this.seekToTime(previousStartTime);
        }
    }

    protected async playNext(): Promise<void> {
        if (this.isOndemandMode) {
            window.dispatchEvent(new PlayerNextAudioEvent(this.currentAudio, this.playlistTitle));
            await this.playNextOnDemandAsync();
        } else if (this.isLiveMode) {
            const nextPosition = this.currentQueuePosition + 1;
            const nextStartTime = this.queue[nextPosition]?.startTime;

            if (!nextStartTime) {
                return;
            }

            window.dispatchEvent(new PlayerNextAudioEvent(this.currentAudio, this.playlistTitle));
            this.seekToTime(nextStartTime);
        }
    }

    protected async restoreSavedState(): Promise<void> {
        if (storage.volume !== null) {
            this.$store.dispatch(AudioActions.SetVolume, storage.volume);
            this.$store.dispatch(LegacyPlayerAction.Volume, storage.volume);
        }

        if (storage.quality) {
            this.$store.dispatch(LegacyPlayerAction.SetQuality, storage.quality);
        }

        if (storage.queue && storage.audioType !== AudioType.Channel) {
            let startTime = storage.audioPosition || 0;

            if (storage.playlistId) {
                const result = await this.handlePlaylistRestore(storage.playlistId);
                startTime = result.isNewPlaylist ? 0 : startTime;
            } else {
                await this.$store.dispatch(MetadataActions.RestoreFromStorage);
                await this.$store.dispatch(MetadataActions.FetchCurrentAndAdjacentMetadata);
            }

            await this.loadAudioAsync(startTime);
            this.showPlayer = true;
        } else if (storage.audioType && storage.audioId) {
            this.$store.dispatch(LegacyLivePlayerAction.RestoreFromStorage);
        }
    }

    protected async playQueue(
        queue: AudioKey[],
        currentAudioIndex = 0,
        playlistTitle?: string,
        isListenLaterList = false,
        startTime?: number
    ): Promise<void> {
        this.showPlayer = true;
        storage.audioType = null;
        this.$store.dispatch(LegacyLivePlayerAction.Deactivate);

        // ios <= 12 kräver att audioelementet touchas innan vi laddar metadata
        if (deviceDetection.isSafari) {
            await this.$store.dispatch(AudioActions.TouchForLegacySafari);
        }

        await this.$store.dispatch(MetadataActions.Clear);
        await this.$store.dispatch(MetadataActions.SetQueue, { queue, currentAudioIndex, playlistTitle, isListenLaterList });
        await this.loadAndPlayQueueIndex(this.currentQueueIndex, startTime);
    }

    protected async fetchAndPlayPlaylist(id: string): Promise<void> {
        const playlist = await storyCollectionApi.getStory(id, { quality: this.quality });
        await this.playPlaylist(playlist);
    }

    protected async playPlaylist(playlist: StoryModel): Promise<void> {
        await this.loadPlaylist(playlist);
        await this.loadAndPlayQueueIndex(this.currentQueueIndex);
    }

    protected async loadPlaylist(playlist: StoryModel, currentIndex: number = 0): Promise<void> {
        this.showPlayer = true;
        storage.audioType = null;
        this.$store.dispatch(LegacyLivePlayerAction.Deactivate);

        // ios <= 12 kräver att audioelementet touchas innan vi laddar metadata
        if (deviceDetection.isSafari) {
            await this.$store.dispatch(AudioActions.TouchForLegacySafari);
        }

        const [queue, audios] = this.convertPlaylistToQueue(playlist);

        await this.$store.dispatch(MetadataActions.Clear);
        await this.$store.dispatch(MetadataActions.SetQueue, {
            queue,
            audios,
            currentAudioIndex: currentIndex,
            playlistTitle: playlist.title,
            playlistId: playlist.id,
            isListenLaterList: false
        });
    }

    protected async loadFromEmbed(audio: AudioKey): Promise<void> {
        if (audio.type === AudioType.Channel || audio.type === AudioType.Extra || audio.type === AudioType.Music) {
            this.$store.dispatch(LegacyLivePlayerAction.RestoreFromStorage, audio);
        } else {
            await this.$store.dispatch(MetadataActions.SetQueue, { queue: [audio], currentAudioIndex: 0 });
            await this.loadQueueIndex(0);
            await this.loadAudioAsync();
        }
    }

    protected unload(): Promise<void> {
        return this.$store.dispatch(AudioActions.Unload);
    }

    // TODO: rename these, they are for live playback
    protected seekToTime(time: Date): void {
        this.$store.dispatch(LegacyLivePlayerAction.LiveSeekTime, time);
    }

    protected seekToLive(): void {
        this.$store.dispatch(LegacyLivePlayerAction.LiveSeekToLive);
        window.dispatchEvent(new PlayerSeekToLiveEvent(this.currentAudio, this.playlistTitle));
    }

    protected seekPercentage(percentage: number): void {
        this.$store.dispatch(LegacyLivePlayerAction.LiveSeekPercentage, percentage);
    }

    private async playPreviousOnDemandAsync(): Promise<void> {
        if (this.currentQueueIndex < 1) {
            return;
        }

        const isPlaying = this.$store.getters[AudioGetters.IsPlaying];
        const prevIndex = this.currentQueueIndex - 1;

        if (isPlaying) {
            await this.loadAndPlayQueueIndex(prevIndex);
        } else {
            await this.loadQueueIndex(prevIndex);
        }
    }

    private async playNextOnDemandAsync(): Promise<void> {
        if (this.currentQueueIndex < this.$store.getters[MetadataGetters.QueueLength] - 1) {
            const isPlaying = this.$store.getters[AudioGetters.IsPlaying];
            const nextIndex = this.currentQueueIndex + 1;

            if (isPlaying) {
                await this.loadAndPlayQueueIndex(nextIndex);
            } else {
                await this.loadQueueIndex(nextIndex);
            }
        }
    }

    private async handlePlaylistRestore(playlistId: string): Promise<{ isNewPlaylist: boolean }> {
        await this.$store.dispatch(MetadataActions.RestoreFromStorage);

        const playlist = await storyCollectionApi.getStory(playlistId, { quality: this.quality });

        const newIds: number[] = playlist.items.map(p => p.audio.id);
        const oldIds = this.queueIds;

        const isSamePlaylist = newIds.length === oldIds.length && newIds.every((value, index) => oldIds[index] === value);
        const index = isSamePlaylist ? this.currentQueueIndex : 0;

        await this.loadPlaylist(playlist, index);
        await this.loadQueueIndex(index);

        return { isNewPlaylist: !isSamePlaylist };
    }

    private convertPlaylistToQueue(playlist: StoryModel): [queue: AudioKey[], audios: AudioMetadata[]] {
        const queue: AudioKey[] = playlist.items.map(p => {
            return { type: AudioType.Clip, id: p.audio.id };
        });

        // fallback to space to hide programName skeleton in player card
        const theme = playlist.header?.length > 0 ? playlist.header : ' ';

        const audios: AudioMetadata[] = playlist.items.map(playlistItem => {
            const audioPublishDate = playlistItem.audio.publishDate;
            return {
                src: playlistItem.audio.audioUrl,
                id: playlistItem.audio.id,
                episodeTitle: playlistItem.title,
                programName: playlist.title,
                theme: theme,
                url: playlist.url,
                tracks: [],
                imageUrl: playlistItem.image.url,
                thumbnailImageUrl: playlistItem.image.url,
                branding: Branding.Default,
                date: dateFormatter.relativeWithTime(audioPublishDate),
                duration: playlistItem.audio.duration,
                statistics: {
                    audioType: AudioType.Clip,
                    audioId: playlistItem.audio.id,
                    publicationId: null
                },
                isPod: false
            } as AudioMetadata;
        });
        return [queue, audios];
    }

    private async loadAudioAsync(startTime?: number): Promise<void> {
        const audio = this.$store.getters[MetadataGetters.CurrentAudio];

        if (!audio) {
            return;
        }

        const trackingState: TrackingState = {
            playlistTitle: this.$store.getters[MetadataGetters.PlaylistTitle],
            startedFromStarttime: !!startTime
        };

        await this.$store.dispatch(AudioActions.Set, { audio, startTime, trackingState });
    }

    private async loadAndPlayQueueIndex(index: number, startTime?: number): Promise<void> {
        await this.loadQueueIndex(index, startTime);
        await this.$store.dispatch(AudioActions.Play);
    }

    private async loadQueueIndex(index: number, startTime?: number): Promise<void> {
        await this.$store.dispatch(AudioActions.Unload);
        await this.$store.dispatch(AudioActions.ResetPosition);
        await this.$store.dispatch(MetadataActions.SetCurrentIndex, index);
        await this.$store.dispatch(MetadataActions.FetchCurrentAndAdjacentMetadata);
        await this.loadAudioAsync(startTime);
    }
}
