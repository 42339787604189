import storage from 'common/storage';

var listenLater = {
    get: function (callback) {
        let list = storage.listenLater || [];
        callback(list);
    },
    exists: function (id, type, callback) {
        listenLater.get(function (data) {
            callback(listenLater.dataExists(id, type, data));
        });
    },
    add: function (id, type) {
        listenLater.get(function (data) {
            id = Number(id);
            const existsInList = listenLater.dataExists(id, type, data);

            if (!existsInList) {
                const obj = { id: id, type: type };
                data.push(obj);
                storage.listenLater = data;
            }
        });
    },
    toggle: function (id, type, callback) {
        listenLater.get(function (data) {
            id = Number(id);
            let exists = listenLater.dataExists(id, type, data);
            if (exists) {
                listenLater.remove(id, type);
                callback(false);
            } else {
                listenLater.add(id, type);
                callback(true);
            }
        });
    },
    setList: function (items) {
        storage.listenLater = items;
    },
    remove: function (id, type) {
        listenLater.get(function (data) {
            const filteredList = data.filter(function (item) {
                return !(item.id === id && item.type === type);
            });

            storage.listenLater = filteredList;
        });
    },
    dataExists: function (id, type, objList) {
        for (var i = 0; i < objList.length; i++) {
            var item = objList[i] || {};
            if (Number(item.id) === Number(id) && item.type === type) {
                return true;
            }
        }
        return false;
    }
};

var localChannel = {
    set: function (id, callback) {
        storage.localChannel = id;
        callback(id);
    },
    get: function (callback) {
        const localChannelId = storage.localChannel || 701;
        callback(localChannel.getChannel(localChannelId));
    },
    getChannel: function (id) {
        return {
            id: id,
            name: localChannel.all[id].name,
            url: localChannel.all[id].url
        };
    },
    all: {
        '200': { name: 'Jämtland', url: '/jamtland' },
        '201': { name: 'Kalmar', url: '/kalmar' },
        '202': { name: 'Sörmland', url: '/sormland' },
        '203': { name: 'Jönköping', url: '/jonkoping' },
        '204': { name: 'Värmland', url: '/varmland' },
        '205': { name: 'Gotland', url: '/gotland' },
        '206': { name: 'Sjuhärad', url: '/sjuharad' },
        '207': { name: 'Malmöhus', url: '/malmo' },
        '208': { name: 'Skaraborg', url: '/skaraborg' },
        '209': { name: 'Norrbotten', url: '/norrbotten' },
        '210': { name: 'Gävleborg', url: '/gavleborg' },
        '211': { name: 'Kristianstad', url: '/kristianstad' },
        '212': { name: 'Göteborg', url: '/goteborg' },
        '213': { name: 'Blekinge', url: '/blekinge' },
        '214': { name: 'Kronoberg', url: '/kronoberg' },
        '215': { name: 'Västerbotten', url: '/vasterbotten' },
        '216': { name: 'Västernorrland', url: '/vasternorrland' },
        '217': { name: 'Västmanland', url: '/vastmanland' },
        '218': { name: 'Uppland', url: '/uppland' },
        '219': { name: 'Väst', url: '/vast' },
        '220': { name: 'Halland', url: '/halland' },
        '221': { name: 'Örebro', url: '/orebro' },
        '222': { name: 'Östergötland', url: '/ostergotland' },
        '223': { name: 'Dalarna', url: '/dalarna' },
        '5283': { name: 'Södertälje', url: '/sodertalje' },
        '701': { name: 'Stockholm', url: '/stockholm' }
    }
};

export default {
    localChannel: localChannel,
    listenLater: listenLater
};
