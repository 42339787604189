import { TrackingMetadata } from '@/tracking/track-types';
import { customEventInit } from './custom-events-common';

interface AddToListClickedEventPayload {
    trackingMetadata: TrackingMetadata;
}

export class AddToListClickedEvent extends CustomEvent<AddToListClickedEventPayload> {
    public static EventName = 'add-to-list-clicked' as const;

    constructor(trackingMetadata: TrackingMetadata) {
        super(
            AddToListClickedEvent.EventName,
            customEventInit({
                trackingMetadata: trackingMetadata
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [AddToListClickedEvent.EventName]: AddToListClickedEvent;
    }
}
