import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import {
    EpisodeButtonsClickedTrackingPayload,
    GtmTrackingData,
    AddToListClickedTrackingData,
    DownloadEpisodeClickedTrackingData,
    RemoveFromListClickedTrackingData,
    ShareButtonClickedTrackingData,
    ShareTrackingData,
    OpenInAppClickedTrackingData
} from './gtm-events';
import { AddToListClickedEvent } from '@/common/custom-events/add-to-list-clicked-event';
import { RemoveFromListClickedEvent } from '@/common/custom-events/remove-from-list-clicked-event';
import { PageMetadataService } from '@/common/page-metadata-service';
import { ShareButtonClickedEvent } from '../../common/custom-events/share-button-clicked-event';
import { ShareEvent } from '@/common/custom-events/share-event';
import { TrackingMetadata } from '../track-types';
import { DownloadEpisodeClickedEvent } from '../../common/custom-events/download-episode-clicked-event';
import { OpenInAppClickedEvent } from '../../common/custom-events/open-in-app-clicked-event';

export type EpisodeButtonsClickedEvent =
    | AddToListClickedEvent
    | RemoveFromListClickedEvent
    | ShareButtonClickedEvent
    | ShareEvent
    | DownloadEpisodeClickedEvent
    | OpenInAppClickedEvent;

export class TrackEpisodeButtonsClickedEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (e: EpisodeButtonsClickedEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(AddToListClickedEvent.EventName, this.handleFn);
        window.addEventListener(RemoveFromListClickedEvent.EventName, this.handleFn);
        window.addEventListener(DownloadEpisodeClickedEvent.EventName, this.handleFn);
        window.addEventListener(ShareButtonClickedEvent.EventName, this.handleFn);
        window.addEventListener(ShareEvent.EventName, this.handleFn);
        window.addEventListener(OpenInAppClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(AddToListClickedEvent.EventName, this.handleFn);
        window.removeEventListener(RemoveFromListClickedEvent.EventName, this.handleFn);
        window.removeEventListener(DownloadEpisodeClickedEvent.EventName, this.handleFn);
        window.removeEventListener(ShareButtonClickedEvent.EventName, this.handleFn);
        window.removeEventListener(ShareEvent.EventName, this.handleFn);
        window.removeEventListener(OpenInAppClickedEvent.EventName, this.handleFn);
    }

    handle(event: EpisodeButtonsClickedEvent): void {
        const payload = event.detail?.trackingMetadata;
        if (!payload) {
            return;
        }

        const publicationData: PublicationData = this.getPublicationData(payload);

        const eventMetadata: EpisodeButtonsClickedTrackingPayload = {
            channelName: payload.channelName,
            channelID: payload.channelId,
            programName: payload.programName,
            programID: payload.programId,
            audioName: publicationData.audioName,
            publicationID: publicationData.publicationId,
            programCategory: payload.category,
            pageType: this.pageMetadataService.pageView?.pageType
        };

        let gtmTrackingData: GtmTrackingData<EpisodeButtonsClickedTrackingPayload> | null = null;

        if (event instanceof AddToListClickedEvent) {
            gtmTrackingData = new AddToListClickedTrackingData(eventMetadata);
        } else if (event instanceof RemoveFromListClickedEvent) {
            gtmTrackingData = new RemoveFromListClickedTrackingData(eventMetadata);
        } else if (event instanceof ShareButtonClickedEvent) {
            gtmTrackingData = new ShareButtonClickedTrackingData(eventMetadata);
        } else if (event instanceof ShareEvent) {
            gtmTrackingData = new ShareTrackingData(eventMetadata);
        } else if (event instanceof DownloadEpisodeClickedEvent) {
            gtmTrackingData = new DownloadEpisodeClickedTrackingData(eventMetadata);
        } else if (event instanceof OpenInAppClickedEvent) {
            gtmTrackingData = new OpenInAppClickedTrackingData(eventMetadata);
        }

        if (gtmTrackingData != null) {
            googleTagManager.track(gtmTrackingData);
        }
    }

    getPublicationData(payload: TrackingMetadata): PublicationData {
        let audioName: string | undefined;
        let publicationId: string | undefined;

        if (payload.broadcastId) {
            publicationId = payload.broadcastId;
            audioName = payload.broadcastName;
        } else if (payload.episodeId) {
            publicationId = payload.episodeId;
            audioName = payload.episodeName;
        } else if (payload.collectionName && payload.storyId) {
            publicationId = payload.collectionName;
            audioName = payload.storyId;
        }

        return {
            publicationId: publicationId,
            audioName: audioName
        };
    }
}

interface PublicationData {
    publicationId?: string;
    audioName?: string;
}
