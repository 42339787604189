import { SearchResponseItem } from '@/api/search-api';
import { AudioKey } from '@/components/models';

class Storage {
    public isLocalStorageAvailable: boolean;
    private isSessionStorageAvailable: boolean;

    private keys: Record<StorageKey, StorageKeyCategory> = {
        'cookie-settings': 'required',
        'local-channel': 'settings',
        'additional-channel': 'settings',
        'player-audio-type': 'required',
        'player-audio-id': 'required',
        'player-quality': 'required',
        'player-volume': 'required',
        'player-queue': 'required',
        'current-queue-index': 'required',
        'player-last-tracked': 'required',
        'player-accumulated-listened-time': 'required',
        'player-stream-position-time': 'required',
        'player-last-active': 'required',
        'listen-later': 'settings',
        'search-history': 'settings',
        'disable-autoplay': 'required',
        'audio-position': 'required',
        'playlist-title': 'required',
        'playlist-id': 'required'
    };

    constructor() {
        this.isLocalStorageAvailable = this.checkLocalStorageAvailability();
        this.isSessionStorageAvailable = this.checkSessionStorageAvailability();
    }

    get cookieSettings(): CookieSettings | null {
        return this.get('cookie-settings');
    }
    set cookieSettings(val: CookieSettings | null) {
        this.set('cookie-settings', val);
    }

    get localChannel(): number | null {
        return this.get('local-channel');
    }
    set localChannel(val: number | null) {
        this.set('local-channel', val);
    }

    get additionalChannel(): number | null {
        return this.get('additional-channel');
    }
    set additionalChannel(val: number | null) {
        this.set('additional-channel', val);
    }

    get volume(): number | null {
        return this.get('player-volume');
    }
    set volume(val: number | null) {
        this.set('player-volume', val);
    }

    get audioId(): number | null {
        return this.get('player-audio-id');
    }
    set audioId(val: number | null) {
        this.set('player-audio-id', val);
    }

    get audioType(): string | null {
        return this.get('player-audio-type');
    }
    set audioType(val: string | null) {
        this.set('player-audio-type', val);
    }

    get quality(): number | null {
        return this.get('player-quality');
    }
    set quality(val: number | null) {
        this.set('player-quality', val);
    }

    get queue(): AudioKey[] | null {
        return this.get('player-queue');
    }
    set queue(val: AudioKey[] | null) {
        this.set('player-queue', val);
    }

    get lastTracked(): number | null {
        return this.get('player-last-tracked');
    }
    set lastTracked(val: number | null) {
        this.set('player-last-tracked', val);
    }

    get lastActive(): Date | null {
        return this.get('player-last-active');
    }
    set lastActive(val: Date | null) {
        this.set('player-last-active', val);
    }

    get accumulatedListenedTime(): number | null {
        return this.getSession('player-accumulated-listened-time');
    }
    set accumulatedListenedTime(val: number | null) {
        this.setSession('player-accumulated-listened-time', val);
    }

    get streamPositionTime(): number | null {
        return this.get('player-stream-position-time');
    }
    set streamPositionTime(val: number | null) {
        this.set('player-stream-position-time', val);
    }

    get listenLater(): Audio[] | null {
        return this.get('listen-later');
    }
    set listenLater(val: Audio[] | null) {
        this.set('listen-later', val);
    }

    get searchHistory(): SearchResponseItem[] | null {
        return this.get('search-history');
    }
    set searchHistory(val: SearchResponseItem[] | null) {
        this.set('search-history', val);
    }

    get disableAutoplay(): boolean | null {
        return this.get('disable-autoplay');
    }
    set disableAutoplay(val: boolean | null) {
        this.set('disable-autoplay', val);
    }

    get currentQueueIndex(): number | null {
        return this.get('current-queue-index');
    }
    set currentQueueIndex(val: number | null) {
        this.set('current-queue-index', val);
    }

    get audioPosition(): number | null {
        return this.get('audio-position');
    }
    set audioPosition(val: number | null) {
        this.set('audio-position', val);
    }

    get playlistTitle(): string | null {
        return this.get('playlist-title');
    }
    set playlistTitle(val: string | null) {
        this.set('playlist-title', val);
    }

    get playlistId(): string | null {
        return this.get('playlist-id');
    }
    set playlistId(val: string | null) {
        this.set('playlist-id', val);
    }

    clearStorage(cookieSettings: UserCookieSettings): void {
        if (!this.isLocalStorageAvailable) {
            return;
        }

        const whitelist: StorageKeyCategory[] = this.getWhitelistedCategories(cookieSettings);

        for (const key of Object.keys(window.localStorage)) {
            const category = this.keys[key as StorageKey];

            const whitelisted = category && whitelist.includes(category);

            if (!whitelisted) {
                window.localStorage.removeItem(key);
            }
        }
    }

    private getWhitelistedCategories(cookieSettings: UserCookieSettings): StorageKeyCategory[] {
        const whitelist: StorageKeyCategory[] = ['required'];

        if (cookieSettings.settingsAllowed) {
            whitelist.push('settings');
        }

        if (cookieSettings.statisticsAllowed) {
            whitelist.push('statistics');
        }

        if (cookieSettings.thirdPartyAllowed) {
            whitelist.push('third-party');
        }
        return whitelist;
    }

    private get(key: StorageKey): any {
        if (!this.isLocalStorageAvailable) {
            return null;
        }

        const value = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    }

    private set(key: StorageKey, value: any): void {
        if (!this.isLocalStorageAvailable) {
            return;
        }

        if (value === undefined || value === null) {
            window.localStorage.removeItem(key);
        } else {
            const stringValue = JSON.stringify(value);
            window.localStorage.setItem(key, stringValue);
        }
    }

    private getSession(key: StorageKey): any {
        if (!this.isSessionStorageAvailable) {
            return null;
        }

        const value = sessionStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    }

    private setSession(key: StorageKey, value: any): void {
        if (!this.isSessionStorageAvailable) {
            return;
        }

        if (!value) {
            sessionStorage.removeItem(key);
        } else {
            const stringValue = JSON.stringify(value);
            sessionStorage.setItem(key, stringValue);
        }
    }

    private checkLocalStorageAvailability(): boolean {
        try {
            const x = '__storage_test__';
            window.localStorage.setItem(x, x);
            window.localStorage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    }

    private checkSessionStorageAvailability(): boolean {
        try {
            const x = '__storage_test__';
            window.localStorage.setItem(x, x);
            window.localStorage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    }
}

type Audio = {
    id: number;
    type: string;
};

type StorageKeyCategory = 'required' | 'settings' | 'statistics' | 'third-party';

type StorageKey =
    | 'cookie-settings'
    | 'local-channel'
    | 'additional-channel'
    | 'player-audio-type'
    | 'player-audio-id'
    | 'player-quality'
    | 'player-volume'
    | 'player-queue'
    | 'current-queue-index'
    | 'player-last-tracked'
    | 'player-accumulated-listened-time'
    | 'player-stream-position-time'
    | 'player-last-active'
    | 'listen-later'
    | 'search-history'
    | 'disable-autoplay'
    | 'audio-position'
    | 'playlist-title'
    | 'playlist-id';

export interface CookieSettings extends UserCookieSettings {
    version: string;
    consentSaved: boolean;
}

export interface UserCookieSettings {
    settingsAllowed: boolean;
    statisticsAllowed: boolean;
    thirdPartyAllowed: boolean;
}

export default new Storage();
