import { TrackingMetadata } from '@/tracking/track-types';
import { customEventInit } from './custom-events-common';

interface RemoveFromListClickedEventPayload {
    trackingMetadata: TrackingMetadata;
}

export class RemoveFromListClickedEvent extends CustomEvent<RemoveFromListClickedEventPayload> {
    public static EventName = 'remove-from-list-clicked' as const;

    constructor(trackingMetadata: TrackingMetadata) {
        super(
            RemoveFromListClickedEvent.EventName,
            customEventInit({
                trackingMetadata: trackingMetadata
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [RemoveFromListClickedEvent.EventName]: RemoveFromListClickedEvent;
    }
}
