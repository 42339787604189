import storage from '@/common/storage';
import { AudioKey, AudioMetadata } from '@/components/models';
import { PlayerMode } from '@/enums/PlayerMode';
import * as MetadataHelpers from '@/store/metadata/metadataHelpers';
import { metadataMutations } from '@/store/metadata/MetadataMutations';
import { RootGetters } from '@/store/RootGetters';
import Vue from 'vue';
import { Module } from 'vuex';
import { RootState } from '../RootState';
import { MetadataState } from './MetadataState';
import { SetQueuePayload } from './SetQueuePayload';
import { LegacyPlayerGetter } from '@/enums/LegacyPlayerGetter';

export default {
    namespaced: true,
    state: {
        queue: [],
        audios: {},
        currentIndex: 0,
        isListenLaterList: false,
        playlistTitle: '',
        playlistId: null
    } as MetadataState,

    getters: {
        currentAudio: ({ queue, audios, currentIndex }): AudioMetadata | null => {
            const currentAudioId = queue[currentIndex]?.id;
            return audios[currentAudioId] || null;
        },
        queueLength: ({ queue }): number => queue.length,
        currentQueuePosition: ({ currentIndex }): number => currentIndex,
        isListenLaterList: ({ isListenLaterList }): boolean => isListenLaterList,
        playlistTitle: ({ playlistTitle }): string => playlistTitle,
        queue: ({ queue, audios }): AudioMetadata[] => queue.map(key => audios[key.id] || {}),
        queueIds: ({ queue }) => queue.map(i => i.id),
        getAudioByQueuePosition:
            ({ queue, audios }) =>
            (id: number): AudioMetadata | null =>
                audios[queue[id]?.id] || null,
        currentAudioKey: state => state.queue[state.currentIndex],
        playlistId: state => state.playlistId
    },

    actions: {
        async setQueue({ dispatch, commit }, payload: SetQueuePayload): Promise<void> {
            commit(metadataMutations.SetIsListenLaterList, payload.isListenLaterList || false);
            commit(metadataMutations.SetQueue, payload.queue);

            if (payload.audios) {
                payload.audios.forEach(a => commit(metadataMutations.AddAudio, a));
            }

            commit(metadataMutations.SetCurrentIndex, payload.currentAudioIndex);
            commit(metadataMutations.SetPlaylistTitle, payload.playlistTitle);
            commit(metadataMutations.SetPlaylistId, payload.playlistId);
            await dispatch(InternalActions.FetchCurrentAndAdjacentMetadata);
        },
        async setCurrentIndex({ commit }, position: number): Promise<void> {
            commit(metadataMutations.SetCurrentIndex, position);
        },
        async qualityChange({ commit, dispatch }): Promise<void> {
            commit(metadataMutations.clearAudios);
            await dispatch(InternalActions.FetchCurrentAndAdjacentMetadata);
        },
        async rearrangeQueue({ commit, state }, queue: AudioKey[]): Promise<void> {
            const existingCurrentAudioKey: AudioKey = state.queue[state.currentIndex];
            const currentAudioIndex: number = queue.findIndex(o => o.id === existingCurrentAudioKey?.id);
            commit(metadataMutations.SetQueue, queue);
            commit(metadataMutations.SetCurrentIndex, currentAudioIndex);
        },
        async restoreFromStorage({ commit, dispatch, state }): Promise<void> {
            if (!storage.queue) {
                return;
            }

            commit(metadataMutations.SetQueue, storage.queue);
            commit(metadataMutations.SetCurrentIndex, storage.currentQueueIndex || 0);
            commit(metadataMutations.SetPlaylistTitle, storage.playlistTitle);
            commit(metadataMutations.SetPlaylistId, storage.playlistId);
        },
        async clear({ commit }): Promise<void> {
            commit(metadataMutations.clearAll);
        },
        async fetchCurrentAndAdjacentMetadata({ commit, state, rootGetters }): Promise<void> {
            const index: number = state.currentIndex;
            const current = state.queue[index];
            const upcomming = state.queue[index + 1];
            const previous = state.queue[index - 1];

            const quality = rootGetters[LegacyPlayerGetter.Quality];
            const isEmbed = rootGetters[RootGetters.IsEmbed];
            const mode = PlayerMode.Ondemand;
            const audios = state.audios;

            await Promise.all([
                await MetadataHelpers.fetchSingleMetadataFromApi({ key: current, audios, isEmbed, quality, mode, commit }),
                await MetadataHelpers.fetchSingleMetadataFromApi({ key: upcomming, audios, isEmbed, quality, mode, commit }),
                await MetadataHelpers.fetchSingleMetadataFromApi({ key: previous, audios, isEmbed, quality, mode, commit })
            ]);
        }
    },
    mutations: {
        setQueue(state: MetadataState, queue: AudioKey[]): void {
            state.queue = queue;
            storage.queue = queue;
        },
        setCurrentIndex(state: MetadataState, index = 0): void {
            if (index < 0 || index >= state.queue.length) {
                return;
            }

            state.currentIndex = index;
            storage.currentQueueIndex = index;
        },
        addAudio(state: MetadataState, audioMetadata: AudioMetadata): void {
            if (!audioMetadata?.id) {
                return;
            }

            Vue.set(state.audios, audioMetadata.id, audioMetadata);
        },
        setPlaylistTitle(state: MetadataState, playlistTitle?: string): void {
            state.playlistTitle = playlistTitle ? playlistTitle : '';
            storage.playlistTitle = playlistTitle ? playlistTitle : null;
        },
        setPlaylistId(state: MetadataState, playlistId?: string): void {
            storage.playlistId = playlistId ? playlistId : null;
            state.playlistId = playlistId ?? null;
        },
        clearAll(state: MetadataState): void {
            state.queue = [];
            state.currentIndex = 0;
            state.audios = {};
            state.isListenLaterList = false;
            state.playlistTitle = '';
            state.playlistId = null;
            storage.queue = null;
            storage.currentQueueIndex = null;
            storage.playlistTitle = null;
            storage.playlistId = null;
        },
        clearAudios(state: MetadataState): void {
            state.audios = {};
        },
        setIsListenLaterList(state: MetadataState, isListenLaterList: boolean): void {
            state.isListenLaterList = isListenLaterList;
        }
    }
} as Module<MetadataState, RootState>;

enum InternalActions {
    FetchCurrentAndAdjacentMetadata = 'fetchCurrentAndAdjacentMetadata'
}

export interface SetSingleAudioPayload {
    audioKey: AudioKey;
}
