export class GtmTrackingData<TPayload> {
    eventName: string;
    payload: TPayload;

    constructor(eventName: string, payload: TPayload) {
        this.eventName = eventName;
        this.payload = payload;
    }
}

export interface ModuleTrackingPayload {
    moduleType: string;
    moduleName: string;
    pageType?: string;
    heading?: string;
    modulePosition?: string;
}

export class ModuleVisibleTrackingData extends GtmTrackingData<ModuleTrackingPayload> {
    constructor(payload: ModuleTrackingPayload) {
        super('modul_visas', payload);
    }
}

export interface ModuleStartedTrackingPayload {
    moduleType: string;
    moduleName: string;
    pageType?: string;
    playlist: string;
    contentType?: string;
}

export class ModuleStartedTrackingData extends GtmTrackingData<ModuleStartedTrackingPayload> {
    constructor(payload: ModuleStartedTrackingPayload) {
        super('modul_start', payload);
    }
}

export interface ModuleClickedTrackingPayload extends ModuleTrackingPayload {
    audioType?: string;
    channelName?: string;
    channelId?: string;
    programName?: string;
    programId?: string;
    audioName?: string;
    publicationId?: number;
    programCategory?: string;
    audioLength?: number;
    playlist?: string;
    daysFromPublish?: string;
    publishDate?: string;
    contentType?: string;
}

export class ModuleClickedTrackingData extends GtmTrackingData<ModuleClickedTrackingPayload> {
    constructor(payload: ModuleClickedTrackingPayload) {
        super('modulklick', payload);
    }
}

export class ShowAllClickedTrackingData extends GtmTrackingData<ModuleTrackingPayload> {
    constructor(payload: ModuleTrackingPayload) {
        super('modul_visa_fler', payload);
    }
}
export class SwipedTrackingData extends GtmTrackingData<ModuleTrackingPayload> {
    constructor(payload: ModuleTrackingPayload) {
        super('modul_swipe', payload);
    }
}

export class SwipedToLastTrackingData extends GtmTrackingData<ModuleTrackingPayload> {
    constructor(payload: ModuleTrackingPayload) {
        super('modul_swipe_till_slut', payload);
    }
}
export interface SelectChannelTrackingPayload {
    moduleType: string;
    moduleName: string;
    pageType?: string;
    channelName?: string;
    channelId?: string;
    liveChannel?: 'p4' | 'extra';
    modulePosition?: string;
}

export class SelectChannelTrackingData extends GtmTrackingData<SelectChannelTrackingPayload> {
    constructor(payload: SelectChannelTrackingPayload) {
        super('modul_valj_kanal', payload);
    }
}

export class ChangeChannelTrackingData extends GtmTrackingData<SelectChannelTrackingPayload> {
    constructor(payload: SelectChannelTrackingPayload) {
        super('modul_byt_kanal', payload);
    }
}

export interface ExternalLinkClickedTrackingPayload {
    linkUrl: string;
    linkText: string;
    pageType?: string;
    deepLinkUrl?: string;
}

export class ExternalLinkClickedTrackingData extends GtmTrackingData<ExternalLinkClickedTrackingPayload> {
    constructor(payload: ExternalLinkClickedTrackingPayload) {
        super('utlank_klick', payload);
    }
}

export interface EpisodeButtonsClickedTrackingPayload {
    channelName?: string;
    channelID?: string;
    programName?: string;
    programID?: string;
    audioName?: string;
    publicationID?: string;
    programCategory?: string;
    audioLength?: number;
    pageType?: string;
}

export class DownloadEpisodeClickedTrackingData extends GtmTrackingData<EpisodeButtonsClickedTrackingPayload> {
    constructor(payload: EpisodeButtonsClickedTrackingPayload) {
        super('ladda_ner', payload);
    }
}

export class OpenInAppClickedTrackingData extends GtmTrackingData<EpisodeButtonsClickedTrackingPayload> {
    constructor(payload: EpisodeButtonsClickedTrackingPayload) {
        super('oppna_i_appen', payload);
    }
}

export class AddToListClickedTrackingData extends GtmTrackingData<EpisodeButtonsClickedTrackingPayload> {
    constructor(payload: EpisodeButtonsClickedTrackingPayload) {
        super('lagg_till_i_min_lista', payload);
    }
}

export class RemoveFromListClickedTrackingData extends GtmTrackingData<EpisodeButtonsClickedTrackingPayload> {
    constructor(payload: EpisodeButtonsClickedTrackingPayload) {
        super('ta_bort_fran_min_lista', payload);
    }
}

export class ShareButtonClickedTrackingData extends GtmTrackingData<EpisodeButtonsClickedTrackingPayload> {
    constructor(payload: EpisodeButtonsClickedTrackingPayload) {
        super('delningsintention', payload);
    }
}

export class ShareTrackingData extends GtmTrackingData<EpisodeButtonsClickedTrackingPayload> {
    constructor(payload: EpisodeButtonsClickedTrackingPayload) {
        super('dela', payload);
    }
}

export interface CategoryButtonClickedTrackingPayload {
    pageType?: string;
    pageTitle?: string;
    linkUrl: string;
    linkText: string;
}

export class CategoryButtonClickedTrackingData extends GtmTrackingData<CategoryButtonClickedTrackingPayload> {
    constructor(payload: CategoryButtonClickedTrackingPayload) {
        super('kategoriknapp_klick', payload);
    }
}

export interface CategoryTagClickedTrackingPayload {
    pageType?: string;
    channelName?: string;
    channelId?: string;
    programName?: string;
    programId?: string;
    programCategory?: string;
}

export class CategoryTagClickedTrackingData extends GtmTrackingData<CategoryTagClickedTrackingPayload> {
    constructor(payload: CategoryTagClickedTrackingPayload) {
        super('kategoritagg', payload);
    }
}

export interface SearchTrackingPayload {
    pageType?: string;
    searchTerm?: string;
}

export class SearchOpenTrackingData extends GtmTrackingData<SearchTrackingPayload> {
    constructor(payload: SearchTrackingPayload) {
        super('sok_oppna', payload);
    }
}

export class SearchClickedEpisodeTrackingData extends GtmTrackingData<SearchTrackingPayload> {
    constructor(payload: SearchTrackingPayload) {
        super('sok_klick_avsnitt', payload);
    }
}

export class SearchClickedProgramTrackingData extends GtmTrackingData<SearchTrackingPayload> {
    constructor(payload: SearchTrackingPayload) {
        super('sok_klick_program', payload);
    }
}

export class SearchClickedArticleTrackingData extends GtmTrackingData<SearchTrackingPayload> {
    constructor(payload: SearchTrackingPayload) {
        super('sok_klick_artikel', payload);
    }
}

export class SearchClickedLatestResultTrackingData extends GtmTrackingData<SearchTrackingPayload> {
    constructor(payload: SearchTrackingPayload) {
        super('sok_klick_senaste_sokningar', payload);
    }
}

export interface ScheduleClickedTrackingPayload {
    channelName?: string;
    channelId?: string;
    pageType?: string;
}

export class ScheduleClickedTrackingData extends GtmTrackingData<ScheduleClickedTrackingPayload> {
    constructor(payload: ScheduleClickedTrackingPayload) {
        super('tablaklick', payload);
    }
}

export interface MenuOpenTrackingPayload {
    pageType?: string;
}

export class MenuOpenTrackingData extends GtmTrackingData<MenuOpenTrackingPayload> {
    constructor(payload: MenuOpenTrackingPayload) {
        super('meny_oppna', payload);
    }
}

export interface MenuClickedTrackingPayload {
    pageType?: string;
    menu?: string;
}

export class MenuClickedTrackingData extends GtmTrackingData<MenuClickedTrackingPayload> {
    constructor(payload: MenuClickedTrackingPayload) {
        super('menyklick', payload);
    }
}

export interface ShowMoreClickedTrackingPayload {
    pageType?: string;
    channelName?: string;
    channelId?: string;
    programName?: string;
    programId?: string;
    programCategory?: string;
}

export class ShowMoreClickedTrackingData extends GtmTrackingData<ShowMoreClickedTrackingPayload> {
    constructor(payload: ShowMoreClickedTrackingPayload) {
        super('visa_fler', payload);
    }
}
